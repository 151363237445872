import React from 'react';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import ProductSuite from './components/ProductSuite/ProductSuite';
import FeaturesOverview from './components/KeyFunctionality/KeyFunctionality';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Testimonials from './components/Testimonials/Testimonials';
import GetInTouch from './components/GetInTouch/GetInTouch';
import Footer from './components/Footer/Footer';

import './home.module.scss';

const Home: React.FC = () => {
  return (
    <div className="home-page">
      <Header />
      <Hero />
      <ProductSuite />
      <FeaturesOverview />
      <HowItWorks />
      {/* <Testimonials /> */}
      <GetInTouch />
      <Footer />
    </div>
  );
};

export default Home;
