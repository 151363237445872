// Hero.tsx
import React from 'react';
import styles from './hero.module.scss'; // Change the import to styles

const Hero: React.FC = () => {
  return (
    <section className={styles.hero}>
      <div className={styles['hero-content']}>
        <h1>Integrated Analytical Platform (IAP)</h1>
        <p>Accurate, intuitive, and powerful forecasting tool at your fingertips.</p>
      </div>
    </section>
  );
};

export default Hero;