// src/pages/TermOfUse/TermOfUse.tsx
import React from 'react';

const TermOfUse: React.FC = () => {
  return (
    <div className="term-of-use">
      <h1>Term of Use Page</h1>
      {/* Rest of your term of use page content */}
    </div>
  );
};

export default TermOfUse;
