import React, { useState } from 'react';
import styles from './getInTouch.module.scss';

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    businessEmail: '',
    phone: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/mbjvegrp', {
        method: 'POST',
        headers: {
          'Accept': 'application/json'
        },
        body: new FormData(e.currentTarget)
      });

      if (response.ok) {
        setStatusMessage("Thanks for your submission!");
        setFormData({
          fullName: '',
          businessEmail: '',
          phone: '',
          message: '',
        });
      } else {
        setStatusMessage("Oops! There was a problem submitting your form");
      }
    } catch (error) {
      setStatusMessage("Oops! There was a problem submitting your form");
    }
  };

  return (
    <section id='contact-us' className={styles['get-in-touch']}>
      <h2>Get in Touch</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles['form-group']}>
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles['form-group']}>
          <label htmlFor="businessEmail">Business Email</label>
          <input
            type="email"
            id="businessEmail"
            name="businessEmail"
            value={formData.businessEmail}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles['form-group']}>
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles['form-group']}>
          <label htmlFor="message">Please tell us how we can help you</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit">Submit</button>
        <p>{statusMessage}</p>
      </form>
    </section>
  );
};

export default GetInTouch;
