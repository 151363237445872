import React from 'react';
import styles from './keyFunctionality.module.scss';

// Assume you have a data file or array for your features
const featuresData = [
  { title: 'Standardized Source of Truth', description: 'A single, reliable data source for all your planning needs.' },
  { title: 'Easy Scenario Sharing Across the Team', description: 'Share and collaborate on planning scenarios with ease.' },
  { title: 'Simulation of Multiple What-If Scenarios', description: 'Test and compare different financial outcomes.' },
  { title: 'Multi-User Environment', description: 'A collaborative platform that supports multiple users simultaneously.' },
  { title: 'Aggregations & Calculations on Different Data Cuts', description: 'Powerful data manipulation for insightful analytics.' },
  { title: 'Advanced Technical Features', description: 'SSO integration for simplified access control. Excel UI with our Excel Add-In. OData service for direct interaction with data.' }
];

const KeyFunctionality: React.FC = () => {
  return (
    <section id="key-functionality" className={styles['key-functionality']}>
      <h2>Functionality That Empowers You</h2>
      <div className={styles['key-functionality-list']}>
        {featuresData.map((feature, index) => (
          <div key={index} className={styles['key-functionality-item']}>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KeyFunctionality;