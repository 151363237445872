// Footer.tsx
import React from 'react';
import styles from './footer.module.scss'; // Ensure you create a Footer.scss file in the same directory

const Footer = () => {
  return (
    <footer className={styles['footer']}>
      <div className={styles['footer-links']}>
        <a href="https://fractal.ai/privacy-policy/">Privacy Policy</a>
        <a href="https://fractal.ai/terms-of-use/">Terms of Use</a>
        <a href="https://fractal.ai/privacy-cookies/">Privacy & Cookies</a>
        <a href="https://fractal.ai/modernslavery/">Modern Slavery Statement</a>
        <a href="https://fractal.ai/wp-content/uploads/2022/04/ABAC-Policy-Fractal.pdf">ABAC Policy</a>
        <a href="https://fractal.ai/wp-content/uploads/2022/04/WB-Policy-Fractal.pdf">WB Policy</a>
        <a href="https://fractal.ai/raise-a-concern/">Raise a Concern</a>
      </div>
      <div className={styles['footer-rights']}>
        Copyright © 2024 All rights reserved Fractal Analytics Inc.
      </div>
    </footer>
  );
};

export default Footer;
