import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styles from './product-suite.module.scss';

const ProductSuite: React.FC = () => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const printScreens = [
    { description: "IAP System Screenshot 1", img: "/img/iap-screenshot-1.png" },
    { description: "IAP System Screenshot 2", img: "/img/iap-screenshot-2.png" },
    { description: "IAP System Screenshot 3", img: "/img/iap-screenshot-3.png" },
  ]

  return (
    <section id="product-suite" className={styles['product-suite']}>
      <div className={styles['product-suite-content']}>
        <h2>Product Suite</h2>
      </div>
      <div className={styles['product-suite-panel']}>
        <div className={styles['text-panel']}>
          <h3>Unlock the Power of Predictive Analytics!</h3>
          <p>Our web-based analytical platform plans future growth by playing different forecast scenarios linked to market growth and impacting levers. Along with collaborative, automated workflow, it improves demand sensing, drives top-line growth, and minimizes working capital costs.
          Try IAP today and experience the difference precision can make!</p>
        </div>
        <div className={styles['print-screen-panel']}>
          <Slider {...settings}>
            {printScreens.map((printScreen, index) => (
                <div key={index}>
                  <img src={printScreen.img} alt={printScreen.description} />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ProductSuite;
