import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './howItWorks.module.scss';

const HowItWorks: React.FC = () => {
  const diagramPath = '/img/iap-arch-diagram.png';
  // Carousel settings for the text section
  const textCarouselSettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const statements = [
    { title: 'Discover the Simplicity of Innovation with Our Cutting-Edge Technology Solution', description: 'At the heart of our platform is a commitment to innovation and excellence. We harness the latest in cutting-edge technology to deliver a solution that\'s not just powerful, but also intuitive and user-friendly. Our managed service approach means you get more than just software; you get a full-fledged partner dedicated to ensuring your success.' },
    { title: 'Real-Time Updates', description: 'Market dynamics can change rapidly. Our tool provides real-time updates, allowing you to make informed decisions based on the latest information. This feature is crucial for staying ahead of the curve and adjusting strategies as needed.' },
    { title: 'Flexible Deployment: Cloud or On-Premises', description: 'Flexibility is key in today’s dynamic business environment. That\'s why our solution is designed to fit seamlessly into your operations, whether you choose to deploy in the cloud for scalability and accessibility or on-premises for enhanced control and security. The power of choice is yours.' },
    { title: 'Certified Expertise', description: 'Our team is not just skilled; they’re certified Microsoft and vendor tool developers, bringing a wealth of knowledge and certified expertise to the table. This means that when you work with us, you\'re working with some of the best minds in the industry—professionals who are as committed to your success as you are.' },
    { title: 'Getting Started Is as Easy as 1-2-3', description: 'Step 1: Contact your administrator to get access and join a community of forward-thinkers who are driving business success through innovation. Step 2: Configure your forecasting parameters with the help of our certified experts, setting the stage for insightful analytics and data-driven decisions. Step 3: Start your forecasting, delve into the insights, and watch as our solution transforms data into actionable intelligence.' },
  ];

  return (
    <section id='how-it-works' className={styles['how-it-works']}>
      <h2>How It Works</h2>
      <div className={styles['how-it-works-panel']}>
        <div className={styles['text-panel']}>
          <Slider {...textCarouselSettings}>
            {statements.map((statement, index) => (
              <div key={index} className={styles['statement']}>
                <h3>{statement.title}</h3>
                <p>{statement.description}</p>
              </div>
            ))}
          </Slider>
        </div>
        <div className={styles['diagram-panel']}>
          <img src={diagramPath} alt="Diagram" />
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
