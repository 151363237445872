// Header.tsx
import React from 'react';
import styles from './header.module.scss';

const Header: React.FC = () => {
  return (
    <section className={styles.header}>
      <div className={styles.logo}>
        <a href="/">
          <img src='/img/fractal_iap_logo.png' alt="IAP Logo" />
        </a>
      </div>
      <nav className={styles.nav}>
        <ul>
        <li><a href="#product-suite">Product</a></li>
          <li><a href="#key-functionality">Functionality</a></li>
          <li><a href="#how-it-works">How it Works</a></li>
          <li><a href="#contact-us">Contact Us</a></li>
        </ul>
      </nav>
      <span className={styles.spacer}>
      </span>
    </section>
  );
};

export default Header;
