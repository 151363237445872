// src/pages/Help/Help.tsx
import React from 'react';

const Help: React.FC = () => {
  return (
    <div className="help">
      <h1>Help Page</h1>
      {/* Rest of your help page content */}
    </div>
  );
};

export default Help;
